<template>
  <div class="video-form">
    <div class="form-content">
      <span class="form-content__text">{{ $t("settings.homeManagement.changeUrl") }}:</span>
      <input
        :class="['form-content__input', { 'missing-field': isVideoUploaded }]"
        type="text"
        v-model="url"
        placeholder="https://www.youtube.com/video-test-link"
      />
      <div class="message-error">
        <span v-if="isVideoUploaded"> * Please insert a valid url to continue </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoForm",
  props: {
    value: {
      required: false,
      type: String,
    },
    isVideoUploaded: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.url = this.value;
  },
  methods: {
    getVideoContentValue() {
      return this.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  display: flex;
  flex-direction: column;
  &__text {
    color: $color-border-container;
    font-size: 17px;
  }
  &__input {
    @include input-password;
    width: 100%;
    @include font-small-standart-text;
    color: $color-primary-company;
    border-radius: 10px;
    border: 1px solid $color-border-container;
    &.missing-field {
      background-color: $missing-fields;
    }
  }
}

.message-error {
  display: flex;
  flex-direction: row-reverse;
  position: static;
  color: $color-error;
  margin-left: 3px;
  bottom: -12px;
  font-size: 0.8rem;
}
</style>
