<template>
  <div class="carousel-mosaic" v-loading="loading">
    <div class="carousel-mosaic__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="carousel-mosaic-content">
      <div class="carousel-button-container">
        <button
          @click="handleShowModal({ showModal: true })"
          class="carousel-button-container__button"
        >
          Create a new item card
        </button>
      </div>
      <ul class="carousel-grid">
        <draggable
          class="carousel-grid-container"
          v-model="itemsCarousel"
          @end="onDragEnd"
          :options="dragOptions"
        >
          <li
            class="carousel-grid-container__item"
            v-for="(item, index) in itemsCarousel"
            :key="index"
          >
            <template v-if="item.type_data === 'image'">
              <span class="order-number">
                {{ index + 1 }}
              </span>
              <button
                @click="handleShowModal({ showModal: true, item })"
                class="edit-button"
                title="Edit"
              >
                <i class="el-icon-edit"></i>
              </button>
              <button @click="deleteItem(item)" class="delete-button" title="Delete">
                <i class="ion ion-trash-outline"></i>
              </button>
              <img :src="item.source" :alt="item.name" class="imagen-carousel" />
            </template>
            <template v-else>
              <span class="order-number">
                {{ index + 1 }}
              </span>
              <!-- Comentado el boton de editar mientras se define si se va a poder editar -->
              <!--  <button
                @click="handleShowModal({ showModal: true, item })"
                class="edit-button"
                title="Edit"
              >
                <i class="el-icon-edit"></i>
              </button> -->
              <button @click="deleteItem(item)" class="delete-button" title="Delete">
                <i class="ion ion-trash-outline"></i>
              </button>
              <!-- <iframe :src="item.source" frameborder="0"></iframe> -->
              <div class="video-container" v-loading="loadingVideos">
                <div :id="'player-' + item.id" class="iframe-wrapper"></div>
              </div>
            </template>
          </li>
        </draggable>
      </ul>
      <CustomModal
        v-model="showModal"
        :modalTitle="isUpdating ? 'Create item card' : 'Edit item card'"
        size="lg"
        centered
      >
        <template #modal-content>
          <ModalItemCard
            @handlerModalImage="handlerModalImage"
            @newVideoItem="handlerNewVideo"
            :contentItemCard="contentItemCard"
            :isUpdating="isUpdating"
          />
        </template>
      </CustomModal>
      <div class="save-button">
        <button @click="updateNewOrder()" class="save-button__button" v-if="IsNewOrder">
          Save Order
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loadYouTubeAPI from "@/Extend/VideoConverter";
import draggable from "vuedraggable";
import CustomModal from "@/components/CustomModal.vue";
import ModalItemCard from "./ModalItemCard.vue";

export default {
  name: "CarouselMosaic",
  components: {
    CustomModal,
    ModalItemCard,
    draggable,
  },
  mixins: [loadYouTubeAPI],
  data() {
    return {
      showModal: false,
      isUpdating: false,
      contentItemCard: {},
      itemsCarousel: [],
      itemsCarouselCopy: [],
      IsNewOrder: false,
      newArrayOrder: [],
      dragOptions: {
        animation: 150,
        ghostClass: "sortable-ghost",
      },
      loading: false,
      loadingVideos: false,
    };
  },
  computed: {
    ...mapGetters({
      dataCarousel: "carousel/getCarouselItems",
    }),
  },
  watch: {
    itemsCarousel(newItems) {
      if (!this.areArraysItemsCarouselEqual(newItems, this.itemsCarouselCopy)) {
        this.IsNewOrder = true;
      } else {
        this.IsNewOrder = false;
      }
    },
  },
  mounted() {
    /* Youtube video formatter */
    this.preloadYoutubeVideo(1000);
  },
  async created() {
    await this.getCarouselInfo();
  },
  methods: {
    areArraysItemsCarouselEqual(newItemsOrder, originalItemsOrder) {
      // If the lengths are different, the arrays are different
      if (newItemsOrder.length !== originalItemsOrder.length) return false;
      for (let i = 0; i < newItemsOrder.length; i += 1) {
        // If the elements are not the same, the arrays are different
        if (JSON.stringify(newItemsOrder[i]) !== JSON.stringify(originalItemsOrder[i])) {
          return false;
        }
      }
      return true;
    },
    async handlerModalImage() {
      if (this.isUpdating) {
        this.itemsCarousel.push(this.dataCarousel);
      } else {
        for (let i = 0; i < this.itemsCarousel.length; i += 1) {
          if (this.itemsCarousel[i].id === this.dataCarousel.id) {
            this.itemsCarousel[i] = this.dataCarousel;
            break;
          }
        }
      }
      this.handleShowModal(false, this.dataCarousel);
    },
    async handlerNewVideo(data) {
      this.itemsCarousel.push(data);
      this.preloadYoutubeVideo(1000);
      this.handleShowModal(false, data);
    },
    async getCarouselInfo() {
      const response = await this.$store.dispatch("carousel/getAllCarouselInfo");
      this.itemsCarousel = response;
      this.itemsCarouselCopy = response;
      return response;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
    handleShowModal({ showModal, item }) {
      this.isUpdating = !item;
      this.showModal = showModal;
      this.contentItemCard = item;
    },
    deleteItem(item) {
      this.swal({
        title: "Are you sure?",
        text: "This item card to carousel will be deleted.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const index = this.itemsCarousel.findIndex((element) => element.id === item.id);
          if (index !== -1) {
            await this.$store.dispatch("carousel/deleteItemCarousel", item.id);
            this.itemsCarousel.splice(index, 1);
            this.$store.commit("carousel/setCarouselItems", this.itemsCarousel);
          }
        }
      });
    },
    onDragEnd() {
      this.itemsCarousel.forEach((item, index) => {
        item.order = index + 1;
      });
      this.preloadYoutubeVideo(0);
    },
    async updateNewOrder() {
      this.IsNewOrder = false;
      this.loading = true;
      const orderArrayFormated = this.itemsCarousel.map((item) => ({
        id: parseInt(item.id, 10),
        order: item.order,
      }));
      const response = await this.$store.dispatch(
        "carousel/updateCarouselOrder",
        orderArrayFormated
      );
      this.$store.commit("carousel/setCarouselItems", this.itemsCarousel);
      if (response.status === 204) {
        this.$message({
          type: "success",
          message: "Reordering Successfully Saved ",
          duration: 4000,
        });
        this.IsNewOrder = false;
        this.loading = false;
      } else {
        this.swal({
          title: "There is something wrong",
          text: `Re order not Sucesfull!`,
          icon: "error",
          dangerMode: true,
        });
      }
    },
    preloadYoutubeVideo(time) {
      this.loadingVideos = true;
      setTimeout(() => {
        this.loadYouTubeAPI();
        this.loadingVideos = false;
      }, time);
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-mosaic {
  display: flex;
  flex-direction: column;
  font-family: $font-family-portal;
  padding: 1% 4% 2% 4%;
  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 0;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}

.carousel-button-container {
  text-align: end;
  &__button {
    @include primary-button;
    height: auto;
    width: 180px;
    padding: 5.5px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
  }
}

.carousel-grid {
  padding: 0;
  margin: 0px;
  list-style: none;
}

.carousel-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 1px;
  margin: 20px 0px;
  &__item {
    width: 100%;
    height: 200px;
    background-color: rgb(212, 212, 212);
    position: relative;
    cursor: grab;
    img,
    iframe {
      opacity: 0.3;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

.order-number {
  position: absolute;
  left: -3px;
  top: -3px;
  color: $color-white !important;
  background-color: $color-primary-company !important;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  z-index: 100;
}

.edit-button {
  position: absolute;
  right: 45px;
  top: 15px;
  color: $color-white !important;
  background-color: $color-border-container !important;
  border: none;
  border-radius: 10px;
  z-index: 100;
  i {
    opacity: 1 !important;
    color: $color-white;
    font-size: 15px;
    cursor: pointer;
  }
}

.delete-button {
  position: absolute;
  top: 15px;
  right: 10px;
  color: $color-white !important;
  background-color: $color-remove-inactive-button !important;
  border: none;
  border-radius: 10px;
  z-index: 100;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  overflow: hidden;

  pointer-events: none;
}

.iframe-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.save-button {
  &__button {
    margin: 25px auto 0px auto;
    display: flex;
    justify-content: space-around;
    @include secondary-button;
    background-color: $color-save-button;
    color: $color-white;
    border: none;

    &:hover {
      background-color: $color-primary-trigger-button-hover;
    }

    width: 20%;
    text-align: center;
    padding: 7px;
  }
}
</style>
