<template>
  <div class="image-form">
    <div class="form-content">
      <DragAndDrop
        v-model="imageContent.image"
        :imageSrc="imageContent.image"
        class="form-content__image"
      />
      <div class="input-container">
        <div class="message-error">
          <span v-if="!isImageUploaded"> * Please upload a picture to continue </span>
        </div>
        <span class="form-content__text">{{ $t("settings.homeManagement.changeUrl") }}:</span>
        <input
          class="form-content__input"
          type="text"
          v-model="imageContent.redirectUrl"
          placeholder="https://www.youtube.com/video-test-link"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/DragAndDrop.vue";

export default {
  name: "ImageForm",
  props: {
    value: {
      type: Object,
      required: false,
    },
    isImageUploaded: Boolean
  },
  components: {
    DragAndDrop,
  },
  data() {
    return {
      imageContent: {
        redirectUrl: "",
        image: null,
      },
    };
  },
  created() {
    this.imageContent = this.value;
  },
  methods: {
    getImageContentValue() {
      return this.imageContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-content {
  &__image {
    width: 100%;
  }
  &__text {
    color: $color-border-container;
    font-size: 17px;
  }
  &__input {
    @include input-password;
    width: 100%;
    font-size: 16px;
    color: $color-primary-company;
    border-radius: 10px;
    border: 1px solid $color-border-container;
    height: 40px;
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.message-error {
  display: flex;
  flex-direction: row-reverse;
  position: static;
  color: $color-error;
  margin-left: 3px;
  bottom: -12px;
  font-size: 0.8rem;
  position: absolute;
  top: 281px;
  right: 0px;
}
::v-deep .drag-and-drop__image-container {
  max-width: 100%;
}
</style>
