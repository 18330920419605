<template>
  <div class="modal-item-card" v-loading="loading">
    <div class="format-card-item" v-if="isUpdating">
      <label class="input-content">
        <input type="radio" name="format" value="image" v-model="selectedFormat" /> Image
      </label>
      <label class="input-content">
        <input type="radio" name="format" value="video" v-model="selectedFormat" /> Video
      </label>
    </div>
    <div class="form-card-item__content">
      <VideoForm
        v-if="selectedFormat === 'video' || validateVideoForm"
        :value="contentItemCard ? contentItemCard.source : ''"
        ref="video"
        :isVideoUploaded="isVideoUploaded"
      />
      <ImageForm
        v-else
        :value="
          contentItemCard
            ? { redirectUrl: contentItemCard.redirect_url, image: contentItemCard.source }
            : {}
        "
        :isImageUploaded="isImageUploaded"
        ref="image"
      />
    </div>
    <div class="button-container">
      <button class="button-container__save" @click="triggerButtonSave()">Save</button>
    </div>
  </div>
</template>

<script>
import VideoForm from "./VideoForm.vue";
import ImageForm from "./ImageForm.vue";

export default {
  name: "ModalItemCard",
  props: {
    contentItemCard: Object,
    isUpdating: Boolean,
  },
  components: {
    ImageForm,
    VideoForm,
  },
  data() {
    return {
      selectedFormat: "image",
      loading: false,
      isImageUploaded: true,
      isVideoUploaded: false,
    };
  },
  computed: {
    validateVideoForm() {
      if (this.contentItemCard && this.contentItemCard.type_data === "video") {
        return true;
      }
      return false;
    },
  },
  watch: {
    contentItemCard() {
      if (this.isUpdating && this.contentItemCard) {
        this.selectedFormat = this.contentItemCard.type_data;
      }
    },
  },
  methods: {
    emptyFields() {
      this.isImageUploaded = !!this.$refs.image.getImageContentValue().image;
      return this.isImageUploaded;
    },
    async triggerButtonSave() {
      if (this.selectedFormat === "image") {
        if (!this.emptyFields()) {
          return;
        }
        const imageInformation = this.$refs.image.getImageContentValue();
        this.dataImageToSend(imageInformation);
        return;
      }
      const videoInformation = this.$refs.video.getVideoContentValue();
      this.dataVideoToSend(videoInformation);
    },
    async dataImageToSend(data) {
      this.loading = true;

      // Validate imageFormData existence and type
      if (!data.image || (!(data.image instanceof File) && this.isUpdating)) {
        this.loading = false; // Reset loading state after error
        return; // Exit the function if validation fails
      }

      const imageFormData = new FormData();
      imageFormData.append("file", data.image);
      let redirectUrlToSend = data.redirectUrl;
      if (redirectUrlToSend === "" || redirectUrlToSend === undefined) {
        redirectUrlToSend = "";
      }
      try {
        if (this.isUpdating) {
          const response = await this.$store.dispatch("carousel/uploadImage", {
            redirectUrlToSend,
            formData: imageFormData,
          });
          if (response.status === 201) {
            this.successMessage("image", "created");
            this.schemeCarouselItem(response.data);
            this.$emit("handlerModalImage");
          } else {
            this.errorMessage("Image", "created");
          }
        } else {
          this.isImageUploaded = false;
          const response = await this.$store.dispatch("carousel/updateCarouselItems", {
            id: this.contentItemCard.id,
            body: { redirect_url: data.redirectUrl },
          });
          if (response.status === 200) {
            this.successMessage("image", "updated");
            this.schemeCarouselItem(response.data);
            this.$emit("handlerModalImage");
          } else {
            this.errorMessage("Image", "updated");
          }
        }
      } catch (error) {
        this.errorMessage("Image", "upload"); // Provide a generic error message
      } finally {
        this.loading = false; // Reset loading state regardless of success or error
      }
    },
    async dataVideoToSend(data) {
      if (this.contentItemCard && this.isUpdating) {
        this.loading = false; // Reset loading state after error
        return;
      }
      if (data !== "") {
        this.loading = true;
        if (this.isUpdating) {
          const response = await this.$store.dispatch("carousel/uploadVideo", data);
          if (response.status === 201) {
            this.$emit("newVideoItem", response.data);
            this.successMessage("Video", "created");
          } else {
            this.errorMessage("Video", "created");
          }
        }
      } else {
        this.isVideoUploaded = true;
      }

      this.loading = false;
    },
    schemeCarouselItem(data) {
      /* eslint-disable camelcase */
      const newCarouselItem = {
        created_at: data.created_at,
        name: data.name,
        id: data.id,
        order: data.order,
        source: data.source,
        redirect_url: data.redirect_url ? data.redirect_url : "",
        type_data: data.type_data,
      };
      this.$store.commit("carousel/setCarouselItems", newCarouselItem);
    },
    errorNotFoundMessage(event, action) {
      this.swal({
        title: "There is something wrong",
        text: `${event} not ${action}!`,
        icon: "error",
        dangerMode: true,
      });
    },
    successMessage(event, action) {
      this.$message({
        type: "success",
        message: `${event} Successfully ${action}`,
        duration: 4000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.format-card-item {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-family: $font-family-portal;
  color: $color-primary-company;
}
.input-content {
  font-weight: 600;
}
.button-container {
  text-align: center;
  &__save {
    @include secondary-button;
    background-color: $color-save-button;
    color: $color-white;
    border: none;
    &:hover {
      background-color: $color-save-button;
    }
    margin-top: 15px;
    width: 45%;
  }
}
::v-deep .drag-and-drop__delete-button {
  display: none;
}
</style>
